/**
 * setting-icon-name.js
 * @file Icon Name 切图文件名配置项
 */

export default {
  /**
   * 服务站收款信息未确认图标
   */
  stationCollectionInfoNotConfirmedSvg:
    'station-collection-info-not-confirmed.svg',

  /**
   * 服务站-合作站-解约确认收款信息弹窗/作废确认收款信息弹窗-下箭头图标
   */
  stationCollectionInfoNotConfirmedArrowIconSvg:
    'station-collection-info-not-confirmed-arrow-icon.svg',

  /**
   * 服务站配件直供图标
   */
  stationFittingDirectSupplySvg: 'station-fitting-direct-supply.svg',

  /**
   * 分拨点配件领用标准订单图标
   */
  distributionCenterFittingClaimOrderStandardOrderSvg:
    'distribution-center-fitting-claim-order-standard-order.svg',

  /**
   * 应用搜索漏斗图标
   */
  appSearchFunnelSvg: 'app-search-funnel.svg',

  /**
   * 应用排序漏斗图标
   */
  appSearchSortSvg: 'app-search-sort.svg',

  /*
   * 维保单批量维保标签图标
   */
  maintOrderBatchImport: 'maint-order-batch-import.svg',

  /**
   * 维保单接车超时图标
   */
  checkInOverTimeSvg: 'check-in-over-time.svg',

  /**
   * 当前位置图标
   */
  locationSvg: 'location.svg',

  /**
   * 车辆高频进站图标
   */
  highFrequencyMaintSvg: 'high-frequency-maint.svg',

  /**
   * 维保单 标准订单 图标
   */
  standardMaintOrderSvg: 'standard-maint-order.svg',

  /**
   * 维保单 维修订单 图标
   */
  repairMaintOrderSvg: 'repair-maint-order.svg',

  /**
   * 报修单 预约维保 图标
   */
  maintReservationSvg: 'maint-reservation.svg',

  /**
   * 报修单 紧急救援 图标
   */
  emergencyRescueSvg: 'emergency-rescue.svg',

  /**
   * 报修单 批量维保 图标
   */
  batchImportSvg: 'batch-import.svg',

  /**
   * 维保单派单超时图标
   */
  dispatchedOvertimeSvg: 'dispatched-overtime.svg',

  /**
   * 维保单待校准里程图标
   */
  vehicleMileageCalibrationNotCalibratedSvg:
    'vehicle-mileage-calibration-not-calibrated.svg',

  /**
   * 维保单无调度人员图标
   */
  hasNoDispatchUserSvg: 'has-no-dispatch-user.svg',

  /**
   * 暂无数据空状态图标
   */
  emptyNoDataSvg: 'empty-no-data.svg',

  /**
   * 暂无车辆空状态图标
   */
  emptyNoVehicleSvg: 'empty-no-vehicle.svg',

  /**
   * 暂无图片图标
   */
  emptyNoImageSvg: 'empty-no-image.svg',

  /**
   * 车队协议价图标
   */
  maintUncoveredVehicleTeamOfferPriceIconSvg:
    'maint-uncovered-vehicle-team-offer-price-icon.svg',

  /**
   * 加价比例标准价图标
   */
  maintUncoveredPipIconSvg: 'maint-uncovered-pip-icon.svg',

  /**
   * 首页欢迎横幅
   */
  shinwellBannerWelcomeSvg: 'shinwell-banner-welcome.svg',

  /**
   * 服务站不可见图标
   */
  maintOrderRemarkInvisible: 'maint-order-remark-invisible.svg',

  /**
   * 星威养车主图标，用于[登录页][修改密码页]
   */
  appLogoMain: 'app-logo-main.svg',

  /**
   * 星威养车海外图标。
   */
  appLogoAbroad: 'app-logo-abroad.svg',

  /**
   * 星威养车副图标，用于[系统菜单头部]
   */
  appLogoMinor: 'app-logo-minor.svg',

  /**
   * 星威养车报告图标
   */
  appLogoLetter: 'app-logo-letter.svg',

  /**
   * 角色权限图标-维保
   */
  permissionMaint: 'permission-maint.svg',

  /**
   * 角色权限图标-财务
   */
  permissionFinance: 'permission-finance.svg',

  /**
   * 角色权限图标-资产
   */
  permissionProperty: 'permission-property.svg',

  /**
   * 角色权限图标-数据
   */
  permissionData: 'permission-data.svg',

  /**
   * 角色权限图标-系统
   */
  permissionSystem: 'permission-system.svg',

  /**
   * 角色权限图标-工具
   */
  permissionTool: 'permission-tool.svg',

  /**
   * 维保单锁定标签
   */
  appTagMaintOrderLockedSvg: 'app-tag-maint-order-locked.svg',

  /**
   * 维保单派单超时标签
   */
  appMaintTagDispatchOverTimeSvg: 'app-maint-tag-dispatch-over-time.svg',

  /**
   * 报价时间标签
   */
  maintOrderQuoteTimeSvg: 'maint-order-quote-time.svg',

  /**
   * 录音图标
   */
  appIconAudio: 'app-icon-audio.svg',

  /**
   * 国家图标
   */
  siteMapCountryCHNSvg: 'site-map-country-CHN.svg',
  siteMapCountryMYSSvg: 'site-map-country-MYS.svg',
  siteMapCountryPHLSvg: 'site-map-country-PHL.svg',

  /**
   * 站点地图聚合点位背景图
   */
  siteMapBgStationSvg: 'site-map-bg-station.svg',
  siteMapBgDistributionCenterSvg: 'site-map-bg-distribution-center.svg',
  siteMapBgTeamCarSvg: 'site-map-bg-team-car.svg',

  /**
   * 核准时间标签
   */
  maintOrderCompletionTimeSvg: 'maint-order-completion-time.svg',

  /**
   * 周边图标
   */
  siteMapIconPeripherySvg: 'site-map-icon-periphery.svg',

  /**
   * 服务站-hover图标
   */
  siteMapIconStationHoverSvg: 'site-map-icon-station-hover.svg',

  /**
   * 车队-hover图标
   */
  siteMapIconTeamCarHoverSvg: 'site-map-icon-team-car-hover.svg',

  /**
   * 分拨点-hover图标
   */
  siteMapIconDistributionCenterHoverSvg:
    'site-map-icon-distribution-center-hover.svg',

  /**
   * 临时站
   */
  siteMapIconTemporaryStationSvg: 'site-map-icon-temporary-station.svg',

  /**
   * 合作站
   */
  siteMapIconCooperativeStationSvg: 'site-map-icon-cooperative-station.svg',

  /**
   * 签约站
   */
  siteMapIconContractedStationSvg: 'site-map-icon-contracted-station.svg',

  /**
   * 系统外服务站
   */
  siteMapIconOutsideStationSvg: 'site-map-icon-outside-station.svg',

  /**
   * 车队
   */
  siteMapIconTeamCarSvg: 'site-map-icon-team-car.svg',

  /**
   * 分拨点
   */
  siteMapIconDistributionCenterSvg: 'site-map-icon-distribution-center.svg',

  /**
   * 车队数量
   */
  siteMapIconTabTeamCarSvg: 'site-map-icon-tab-team-car.svg',

  /**
   * 分拨点数量
   */
  siteMapIconTabDistributionCenterSvg:
    'site-map-icon-tab-distribution-center.svg',

  /**
   * 服务站数量
   */
  siteMapIconTabStationSvg: 'site-map-icon-tab-station.svg',

  /**
   * 车队数量
   */
  siteMapIconTabTeamCarHoverSvg: 'site-map-icon-tab-team-car-hover.svg',

  /**
   * 分拨点数量
   */
  siteMapIconTabDistributionCenterHoverSvg:
    'site-map-icon-tab-distribution-center-hover.svg',
  /**
   * 服务站数量
   */
  siteMapIconTabStationHoverSvg: 'site-map-icon-tab-station-hover.svg',

  /**
   * 站点数量统计背景
   */
  siteMapIconTabBgSvg: 'site-map-icon-tab-bg.svg',

  /**
   * 全屏
   */
  siteMapIconFullScreenSvg: 'site-map-icon-full-screen.svg',

  /**
   * 退出全屏
   */
  siteMapIconExitFullScreenSvg: 'site-map-icon-exit-full-screen.svg',

  /**
   * 通知
   */
  maintPlanNoticeSvg: 'maint-plan-notice.svg',

  /**
   * 车辆生命周期路线背景
   */
  vehicleLifecycleRoadBgSvg: 'vehicle-lifecycle-road-bg.svg',

  /**
   * 车辆生命周期路线车辆
   */
  vehicleLifecycleRoadCarSvg: 'vehicle-lifecycle-road-car.svg',

  /**
   * 车辆生命周期路线日期背景
   */
  vehicleLifecycleRoadDateBg1Svg: 'vehicle-lifecycle-road-date-bg-1.svg',
  vehicleLifecycleRoadDateBg2Svg: 'vehicle-lifecycle-road-date-bg-2.svg',
  vehicleLifecycleRoadDateBg3Svg: 'vehicle-lifecycle-road-date-bg-3.svg',
  vehicleLifecycleRoadDateBg4Svg: 'vehicle-lifecycle-road-date-bg-4.svg',

  /**
   * 车辆生命周期路线标记-维修
   */
  vehicleLifecycleIconRepairSvg: 'vehicle-lifecycle-icon-repair.svg',

  /**
   * 车辆生命周期路线标记-检查
   */
  vehicleLifecycleIconInspectSvg: 'vehicle-lifecycle-icon-inspect.svg',

  /**
   * 车辆生命周期路线标记-保养
   */
  vehicleLifecycleIconMaintSvg: 'vehicle-lifecycle-icon-maint.svg',

  /**
   * 车辆生命周期路线标记-维修+保养
   */
  vehicleLifecycleIconMaintAndRepairSvg:
    'vehicle-lifecycle-icon-maint-and-repair.svg',

  /**
   * 车辆生命周期路线标记-检查+维修
   */
  vehicleLifecycleIconInspectAndRepairSvg:
    'vehicle-lifecycle-icon-inspect-and-repair.svg',

  /**
   * 车辆生命周期路线标记-保养+检查
   */
  vehicleLifecycleIconMaintAndInspectSvg:
    'vehicle-lifecycle-icon-maint-and-inspect.svg',

  /**
   * 车辆生命周期路线标记-维修+保养+检查
   */
  vehicleLifecycleIconMaintAndRepairAndInspectSvg:
    'vehicle-lifecycle-icon-maint-and-repair-and-inspect.svg',

  /**
   * 车辆维修历史标点
   */
  vehicleHistoryDistributionSvg: 'vehicle-history-distribution.svg',

  /**
   * 车辆维修历史高亮标点
   */
  vehicleHistoryDistributionHoverSvg: 'vehicle-history-distribution-hover.svg',
};
