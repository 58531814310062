import antdZhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjsZhCN from 'dayjs/esm/locale/zh-cn';

import appZhCN from '@/locales/zh-CN/app/app.js';
import homeZhCN from '@/locales/zh-CN/home/home.js';
import loginZhCN from '@/locales/zh-CN/login/login.js';
import resetPasswordZhCN from '@/locales/zh-CN/reset-password/reset-password.js';
import maintZhCN from '@/locales/zh-CN/maint/maint.js';
import accountManageZhCN from '@/locales/zh-CN/account-manage/account-manage.js';
import userZhCN from '@/locales/zh-CN/user/user.js';
import permissionManageZhCN from '@/locales/zh-CN/permission-manage/permission-manage.js';
import vehicleTeamZhCN from '@/locales/zh-CN/vehicle-team/vehicle-team.js';
import vehicleManagementZhCN from '@/locales/zh-CN/vehicle-management/vehicle-management.js';
import stationMonthlyStatementZhCN from '@/locales/zh-CN/station-monthly-statement/station-monthly-statement.js';
import stationAmercementZhCN from '@/locales/zh-CN/station-amercement/station-amercement.js';
import stationMonthlyStatementConfirmationZhCN from '@/locales/zh-CN/station-monthly-statement-confirmation/station-monthly-statement-confirmation.js';
import directSupplyManageZhCN from '@/locales/zh-CN/direct-supply-manage/direct-supply-manage.js';
import billingZhCN from '@/locales/zh-CN/billing/billing.js';
import vehicleTeamMonthlyInspectionReportZhCN from '@/locales/zh-CN/vehicle-team-monthly-inspection-report/vehicle-team-monthly-inspection-report.js';
import vehicleInspectionReportZhCN from '@/locales/zh-CN/vehicle-inspection-report/vehicle-inspection-report.js';

const components = {
  localeAntd: antdZhCN,
};

const plugin = {
  localeDayjs: dayjsZhCN,
};

const pages = {
  ...appZhCN,
  ...homeZhCN,
  ...loginZhCN,
  ...resetPasswordZhCN,
  ...maintZhCN,
  ...accountManageZhCN,
  ...userZhCN,
  ...permissionManageZhCN,
  ...vehicleTeamZhCN,
  ...vehicleManagementZhCN,
  ...stationMonthlyStatementZhCN,
  ...stationAmercementZhCN,
  ...stationMonthlyStatementConfirmationZhCN,
  ...directSupplyManageZhCN,
  ...billingZhCN,
  ...vehicleTeamMonthlyInspectionReportZhCN,
  ...vehicleInspectionReportZhCN,
};

export default {
  ...components,
  ...plugin,
  ...pages,
};
