export default {
  'billing.common.billingCreditedTime': 'Entry Time',
  'billing.common.maintRequestTime': 'Order Creation Time',
  'billing.common.settlementStatusLabel': 'Billing Status',
  'billing.common.vehicleTeamName': 'Fleet Name',
  'billing.common.stationId': 'Station ID',
  'billing.common.cooperationTypeLabel': 'Cooperation Type',
  'billing.common.maintChargeTypeLabel': 'Fee Type',
  'billing.common.stationCountryCode': 'Currency',
  'billing.common.maintFeeInvoicingName': 'Maintenance fee invoicing',
  'billing.common.billingAmount': 'Total',
  'billing.common.trailerFeeInvoicingName': 'Invoicing of towing charges',
  'billing.common.settledAmount': 'Recorded Amount',
  'billing.common.deductedAmount': 'Deductible Amount',
  'billing.common.amerceMaintOrderNos': 'Deduction Order No.',
  'billing.common.paymentMethod': 'Payment Method',
  'billing.common.maintCoveredAmount': 'In-warranty Total',
  'billing.common.maintUncoveredAmount': 'Out-of-warranty Total',
  'billing.common.maintFreedAmount': 'Reduction Total',
  'billing.common.expensesStatementDetails': 'Expense Bill Detail',
  'billing.common.statementNumber': 'Billing Number',
  'billing.common.totalCostOfWorkingHoursAndAccessories':
    'Total Fee Of Man-hours & Fittings',
  'billing.common.otherFeeTotal': 'Total Other Fees',
  'billing.common.freedFeeTotal': 'Total Reductions',
};
