export default {
  'billing.common.billingCreditedTime': '入账时间',
  'billing.common.maintRequestTime': '维保单创建时间',
  'billing.common.settlementStatusLabel': '结算状态',
  'billing.common.vehicleTeamName': '车队名称',
  'billing.common.stationId': '服务站ID',
  'billing.common.cooperationTypeLabel': '合作类型',
  'billing.common.maintChargeTypeLabel': '费用类型',
  'billing.common.stationCountryCode': '结算币种',
  'billing.common.maintFeeInvoicingName': '维修费开票情况',
  'billing.common.billingAmount': '费用合计',
  'billing.common.trailerFeeInvoicingName': '拖车费开票情况',
  'billing.common.settledAmount': '入账金额',
  'billing.common.deductedAmount': '抵扣金额',
  'billing.common.amerceMaintOrderNos': '扣款维保单号',
  'billing.common.paymentMethod': '付款方式',
  'billing.common.maintCoveredAmount': '保内总计',
  'billing.common.maintUncoveredAmount': '保外总计',
  'billing.common.maintFreedAmount': '减免总计',
  'billing.common.expensesStatementDetails': '费用账单明细',
  'billing.common.statementNumber': '结算单号',
  'billing.common.totalCostOfWorkingHoursAndAccessories': '工时配件费合计',
  'billing.common.otherFeeTotal': '其他费用',
  'billing.common.freedFeeTotal': '减免总计',
};
