export default {
  'vehicleTeamMonthlyInspectionReport.common.viewReport': 'View report',
  'vehicleTeamMonthlyInspectionReport.common.forMoreItems':
    'For more items, please check browser',
  'vehicleTeamMonthlyInspectionReport.common.otherForMoreItems':
    'Others (For more details of inspection items, please log in to the computer to view.)',
  'vehicleTeamMonthlyInspectionReport.common.inspectionItems':
    'Inspection items',
  'vehicleTeamMonthlyInspectionReport.common.inspectionAbnormalStatistics':
    'Inspection Abnormal Statistics',
  'vehicleTeamMonthlyInspectionReport.common.detailsOfInspectionAbnormals':
    'Details of Inspection Abnormals',
};
