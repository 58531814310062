export default {
  'stationMonthlyStatementConfirmation.common.title': '车辆维修费用确认函',
  'stationMonthlyStatementConfirmation.common.xingwei':
    '星威汽车科技(无锡)有限公司:',
  'stationMonthlyStatementConfirmation.common.partOne':
    '本站 ({stationName}) 确认截止 {statementEndDate}，星威应结算的维修费用总金额为 {repairFeeUnsettled}{unit}，没有未结算维修单据。',
  'stationMonthlyStatementConfirmation.common.partTwo':
    '贵公司将无需承担并支付截止日之前在本结算单以外的维修费用。',
  'stationMonthlyStatementConfirmation.common.partThree':
    '本站在此对于附件中的实际维修情况及费用发生真实性予以确认。',
  'stationMonthlyStatementConfirmation.common.seal': '盖章',
  'stationMonthlyStatementConfirmation.common.annexI':
    '附件一：维保单公对公结算',
  'stationMonthlyStatementConfirmation.common.amountTo': '总计',
  'stationMonthlyStatementConfirmation.common.annexII': '附件二：维保单已结算',
  'stationMonthlyStatementConfirmation.common.annexIII': '附件三：外部导入账单',
  'stationMonthlyStatementConfirmation.common.annexIV': '附件四：扣款明细',
  'stationMonthlyStatementConfirmation.common.annexV':
    '附件五：外部导入账单扣款',
  'stationMonthlyStatementConfirmation.common.annexVI':
    '附件六：直供件入库明细',
  'stationMonthlyStatementConfirmation.common.annexVII':
    '附件七：直供件出库明细（仅含“抵减退货”）',
  'stationMonthlyStatementConfirmation.common.settleEndDate':
    '{settleEndDate}前',
  'stationMonthlyStatementConfirmation.common.payCycle': '付费周期:',
  'stationMonthlyStatementConfirmation.common.theAmountOtherPaid':
      '其他车队实付金额:',
  'stationMonthlyStatementConfirmation.common.theAmountAnNengPaid':
      '安能实付金额:',
  'stationMonthlyStatementConfirmation.common.theAmountActuallyPaid':
    '实付金额:',
  'stationMonthlyStatementConfirmation.common.amountPenalty': '扣款金额',
  'stationMonthlyStatementConfirmation.common.marginAmountDeduct':
    '应扣保证金金额',
  'stationMonthlyStatementConfirmation.common.directSupplyFittingDiscountFee':
    '抵减配件金额',
  'stationMonthlyStatementConfirmation.common.directSupplyFittingFee':
    '应收配件金额',
  'stationMonthlyStatementConfirmation.common.repairFeeUnsettled': '应结算金额',
  'stationMonthlyStatementConfirmation.common.settlementRate': '结算比例',
  'stationMonthlyStatementConfirmation.common.repairFeeSettled': '已结算金额',
  'stationMonthlyStatementConfirmation.common.collect': '汇总',
  'stationMonthlyStatementConfirmation.common.orderCompletionTime':
    '订单完成时间',
  'stationMonthlyStatementConfirmation.common.orderNumber': '订单号',
  'stationMonthlyStatementConfirmation.common.approvalTime': '审核通过时间',
  'stationMonthlyStatementConfirmation.common.maintOrderNo': '维保单号',
  'stationMonthlyStatementConfirmation.common.amercementConfirmedTime':
    '扣款确认时间',
  'stationMonthlyStatementConfirmation.common.amerceAmount': '扣缴金额',
  'stationMonthlyStatementConfirmation.common.historyAmercementFee':
    '上月结转扣款',
  'stationMonthlyStatementConfirmation.common.fittingName': '配件名称',
  'stationMonthlyStatementConfirmation.common.model': '型号',
  'stationMonthlyStatementConfirmation.common.inventoryQuantity': '入库数量',
  'stationMonthlyStatementConfirmation.common.totalPurchasePrice': '采购总价',
  'stationMonthlyStatementConfirmation.common.unitPurchasePrice': '采购单价',
  'stationMonthlyStatementConfirmation.common.whetherToDeduct': '是否抵减',
  'stationMonthlyStatementConfirmation.common.postingDate': '入账日期',
  'stationMonthlyStatementConfirmation.common.outboundQuantity': '出库数量',
  'stationMonthlyStatementConfirmation.common.outboundDate': '出库日期',
  'stationMonthlyStatementConfirmation.common.externalMaintOrderNo': '外部单号',
  'stationMonthlyStatementConfirmation.common.settleAmount': '结算金额',
  'stationMonthlyStatementConfirmation.common.commissionAmount': '抽佣金额',
  'stationMonthlyStatementConfirmation.common.deductAmount': '扣款金额',
  'stationMonthlyStatementConfirmation.common.deductRemark': '扣款备注',

  'stationMonthlyStatementConfirmation.common.directSupplyFittingIncreaseInventoryPurchaseTotalPrice':
    '直供件采购金额',
  'stationMonthlyStatementConfirmation.common.directSupplyFittingIncreaseInventoryPurchaseExpenseOffsetTotalPrice':
    '直供件抵减金额',
  'stationMonthlyStatementConfirmation.common.directSupplyFittingDecreaseInventoryTotalPrice':
    '金额总计',
};
